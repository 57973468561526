(function () {
	
	//----------------------------------------------------------------
	//Feld in Objekt-Details
	//----------------------------------------------------------------
	
    'use strict';

    var LocationField = {

        //const
        FIELD_TYPE_DIVIDER: 0,
        FIELD_TYPE_STRING: 1,
        FIELD_TYPE_INT: 2,
        FIELD_TYPE_FLOAT: 3,
        FIELD_TYPE_DATE: 4,
        FIELD_TYPE_LIST: 5,
        FIELD_TYPE_LIST_OR_STRING: 6,
        FIELD_TYPE_LONG_TEXT: 7,
        FIELD_TYPE_BOOL: 8,
        FIELD_TYPE_LIST_BUTTONS: 9,
		FIELD_TYPE_TIME: 10,
		FIELD_TYPE_PHONE_NO: 11,
		FIELD_TYPE_RUNNING_TIME: 12,
	    FIELD_TYPE_FOTO: 13,

        locationFieldPrototype: {

            fromObj: function (t) {
                this.clientId = parseInt(t.clientId, 10);
                this.id = parseInt(t.id, 10);
                this.group = t.group;
                this.name = t.name;
                this.type = parseInt(t.type, 10);
                this.orderIndex = parseInt(t.orderIndex, 10);
                this.locationType = parseInt(t.locationType, 10);
                this.suffix = t.suffix;
                this.editable = parseInt(t.editable, 10);
                this.hint = t.hint;
                this.placeholder = t.placeholder;
                if (t.options)
                    this.options = t.options.split("#");
                else
                    this.options = [];
                this.minVal = parseFloat(t.minVal);
                this.maxVal = parseFloat(t.maxVal);
                this.step = parseFloat(t.step);
                //this.isName = parseInt(t.isName, 10);
                this.isRequired = parseInt(t.isRequired, 10);
                this.isSearchable = parseInt(t.isSearchable, 10);
                this.defaultValue = t.defaultValue;
                this.flags = t.flags;
                return this;
            },

            serialize: function () {

                var o = {};

                o.clientId = this.clientId;
                o.id = this.id;
                o.group = this.group;
                o.name = this.name;
                o.type = this.type;
                o.orderIndex = this.orderIndex;
                o.locationType = this.locationType;
                o.suffix = this.suffix;
                o.editable = this.editable;
                o.hint = this.hint;
	            o.placeholder = this.placeholder;
                o.options = this.options.join("#");
                o.flags = this.flags;

                o.minVal = this.minVal;
                o.maxVal = this.maxVal;
                o.step = this.step;
                //o.isName = this.isName;
                o.isRequired = this.isRequired;
                o.isSearchable = this.isSearchable;
                o.defaultValue = this.defaultValue;

                return o;
            }
        },
	
	    //----------------------------------------------------------------
	    
	    //alle von bestimmtem Typ holen
        getLocationFields: function (type) {
            var a = [];
            for (var i = 0; i < model.locationFields.length; i++)
                if (model.locationFields[i].locationType === type) {
                    a.push(model.locationFields[i]);
                }
            return a;
        },
	
	    //----------------------------------------------------------------
	    
	    //einzelnes Feld suchen
        getLocationField: function (id) {
            for (var i = 0; i < model.locationFields.length; i++)
                if (model.locationFields[i].id === id) {
                    return model.locationFields[i];
                }
            return null;
        },
	
	    //----------------------------------------------------------------

        createLocationField: function () {
            return Object.create(LocationField.locationFieldPrototype);
        }
    };

    window.LocationField = LocationField;
}());

