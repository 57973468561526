(function () {
	
	//-------------------------------------------
	//Manager für Screen-Anzeige etc.
	//-------------------------------------------
	
	/*global
	 App:true,
	 Ladda:true,
	 toastr:true,
	 Account:true,
	 Layout:true,
	 adminSettingsManager:true,
	 settingsManager:true,
	 locationManager:true,
	 protocolManager:true,
	 protocolEditManager:true,
	 taskDetailManager:true,
	 taskListManager:true,
	 requestListManager:true,
	 userListManager:true,
	 userDetailManager:true,
	 userGroupListManager:true,
	 userGroupDetailManager:true,
	 constants:true,
	 clientManager:true,
	 authManager:true,
	 UserRole:true,
	 requestDetailManager:true,
	 taskRequestEditorManager:true,
	 incidentListManager:true,
	 overviewManager:true*/
	
	'use strict';
	
	/* allgemeine View-Funktionen */
	var viewManager = {
		
		REFRESH_MODE_LOAD: 0,
		REFRESH_MODE_RENDER: 1,
		curRefreshMode: 0,
		
		//------------------------------------------------------------------------------------------
		
		//aktive Klasse
		curViewManager: null,
		
		//------------------------------------------------------------------------------------------
		
		//Metronic aktualisieren
		updateLayout: function () {
			//noinspection JSUnresolvedFunction
			Layout.initContent();
        },
		
		//Scrolling
		scrollToElement: function (el, delta) {
			App.scrollTo(el, delta);
		},

		getScrollTop: function (){
			var el = document.scrollingElement || document.documentElement;
			return el.scrollTop;
		},
		setScrollTop: function(pos){
			var el = document.scrollingElement || document.documentElement;
			el.scrollTop = pos;
		},
		
		//------------------------------------------------------------------------------------------
		
		//wenn nicht "force", kann Render nicht entfernt werden (Thema sukzessive Refreshes)
		setRefreshMode: function (trgMode, force) {
			
			if (force === undefined)
				force = false;
			
			//entfernbar?
			if ((this.curRefreshMode === this.REFRESH_MODE_RENDER) && (trgMode === this.REFRESH_MODE_LOAD) && (!force))
				return;
			
			var btnRefresh = $("#btnRefresh");
			if (trgMode === this.REFRESH_MODE_RENDER) {
				btnRefresh.addClass("active");
				this.curRefreshMode = this.REFRESH_MODE_RENDER;
			}
			else {
				btnRefresh.removeClass("active");
				this.curRefreshMode = this.REFRESH_MODE_LOAD;
			}
			
		},
		
		//------------------------------------------------------------------------------------------
		
		//Button blockierend machen
		//return false -> is already blocking
		setButtonBlocking: function (el, forceBlocking) {
			
			if (forceBlocking) {
				
				el.each(function (index, obj) {
					var l = Ladda.create(obj);
					l.start();
				});
				
			}
			
			if (el.hasClass("btnActivated")) {
				return false;
			}
			el.addClass("btnActivated");
			return true;
		},
		
		//alle Blocking-Button aktualisieren
		updateButtons: function () {
			setTimeout(function () {
				
				//reset
				$(".btnHasSpinner").removeClass("btnActivated");
				Ladda.stopAll();
				
				//und direkt an click binden
				Ladda.bind('.btnHasSpinner');
			}, 20);
		},
		
		//------------------------------------------------------------------------------------------
		
		//Menü auswählen
		setSelectedMenuItem: function (menu, submenu, submenu2) {
			
			$("#sideMenu li").removeClass("active");
			$("#sideMenu li a .selected").remove();
			
			if (menu) {
				$("#sideMenu #" + menu).addClass("active");
				$("#sideMenu #" + menu + " li a").append('<span class="selected"></span>');
				if (submenu) {
					$("#sideMenu #" + submenu).addClass("active");
					$("#sideMenu #" + submenu + " li a").append('<span class="selected"></span>');
					if (submenu2)
						$("#sideMenu #" + submenu2).addClass("active");
				}
			}
		},
		
		//activate Bootstrap Nav Tab
		activateNavTab: function (parentElementId, index) {
			$(parentElementId + " ul.nav.nav-tabs li").removeClass("active");
			if (index >= 0) {
				var tabEl = $(parentElementId + " ul.nav.nav-tabs li:nth-child(" + (index + 1) + ")");
				//mark
				tabEl.addClass("active");

				//trg?
				var trgPaneId = tabEl.find("a[data-toggle=\"tab\"]").attr("href");
				$(parentElementId + " .tab-content div.tab-pane").removeClass("active in");
				$(parentElementId + " .tab-content " + trgPaneId).addClass("active in");
			}
		},
		
		//---------------------------------------------------------------
		//Routing and Browser History
		//---------------------------------------------------------------

		init: function(){
			this.originalHref = window.location.href;
			var url = this.originalHref.split("#");
			this.originalHref = url[0];
			this.originalHrefArgs = url[1];
		},

		originalHref: null,
		hasHrefBeenParsed: false,
		isPageLoad: true,
		
		//eine Seite aufrufen: Vorbereitung
		showPage: function (pageId, argsObj, pushState, askExit, forceReload, restoreState, callbackComplete) {

			var that = this;
			
			if (!argsObj)
				argsObj = {};
			if (pushState === undefined)
				pushState = true;
			if (askExit === undefined)
				askExit = true;
			if (forceReload === undefined)
				forceReload = false;
			if (restoreState === undefined)
				restoreState = false;
			
			//logged in?
			if (model.curUserId < 0)
				return false;
			
			var mustRestoreState = false;
			if (pageId === model.curPageId)
				if (!forceReload) {
					return false;
				}
				else {
					if (restoreState)
						mustRestoreState = true;
				}
			
			if (askExit) {
				if (this.isFormPage()) {
					if (model.isCurFormDirty) {
						pg.confirmMsg("Achtung", "<strong>Möchten Sie die Formulareingabe wirklich beenden?</strong><br>Nicht gespeicherte Änderungen gehen verloren.", function (e) {
							if (e) {
								that.execShowPage(pageId, argsObj, pushState);
								return false;
							}
						});
						return false;
					}
				}
			}
			that.execShowPage(pageId, argsObj, pushState, mustRestoreState, callbackComplete);
			
			return false;
		},
		
		//eine Seite aufrufen: Ausführung
		execShowPage: function (pageId, argsObj, pushState, restoreState, callbackComplete) {
			
			if (!argsObj)
				argsObj = {};
			if (pushState === undefined)
				pushState = false;
			
			argsObj.urlArgs = {};
			
			//console.log("showPage " + pageId);
			if (!this.hasHrefBeenParsed) {
				this.hasHrefBeenParsed = true;
				try {
					//anhand der URL entscheiden
					if (!pageId) {
						var fragments = this.originalHrefArgs.split("&");
						for(var i = 0; i < fragments.length; i++) {
							var a = fragments[i].split("=");
							if (a.length === 2) {
								var key = a[0];
								var val = a[1];
								
								//spezial: PageId
								if ((i === 0) || key === "pageId")
									pageId = val;
								
								//merken für weitere Verarbeitung
								argsObj.urlArgs[key] = val;
							}
						}
					}
				}
				catch (e) {
				}
			}
			
			//lösche ggf. vorhandene Markierung entfernen
			this.setRefreshMode(this.REFRESH_MODE_LOAD, true);
			
			//falls kein Rück-Schritten, Seiten-Zustand neu erzeugen
			if (pushState) {
				model.curStateDetails = {
					curTabIndex: 0,
					scrollPosition: this.getScrollTop()
				};
			}
			
			//Startansicht ist abhängig von Berechtigungen
			if (!pageId) {
				if (authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL,
						UserRole.PERMISSION_TASK_LIST_OWN,
						UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
					pageId = constants.PAGE_TASK_LIST;
				}
			}
			if (!pageId) {
				if (authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL,
						UserRole.PERMISSION_REQUEST_LIST_OWN,
						UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER,
						UserRole.PERMISSION_REQUEST_LIST_RELEASED,
						UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)) {
					pageId = constants.PAGE_REQUEST_LIST;
				}
			}
			
			//anderer Client?
			if (argsObj.urlArgs.clientId) {
				var cId = parseInt(argsObj.urlArgs.clientId);
				if (cId !== model.curClientId) {
					//ist dieser Account vorhanden?
					var account = Account.getAccount(cId);
					if (account) {
						
						//alles ausblenden
						$("#pageBox").hide();
						
						//automatisch Account wechseln
						model.showPageId = pageId;
						model.showPageArgsObj = argsObj;
						authManager.switchAccount(cId);
						return;
					}
					else {
						//Hinweis
						pg.showMsg("Der aufgerufene Link passt nicht zu Ihrem Benutzerprofil.");
						pageId = constants.PAGE_OVERVIEW;
					}
				}
			}

			//bisherige Seite abschließen
			if (this.curViewManager) {
				var leaveFunction = (this.curViewManager).onBeforeLeave;
				if (leaveFunction) {
					$.proxy(leaveFunction, this.curViewManager)();
				}
			}

			model.curPageId = pageId;
			model.curPageArgs = argsObj;
			
			//URL vorbereiten
			//var newUrl = this.originalHref + "#pageId=" + pageId + "&clientId=" + model.curClientId;
			var newUrl = this.getUrlRoot();
			
			var taskId,
				requestId,
				formId,
				protocolId,
				locationId,
				userId,
				userGroupId,
				groupId;
			
			//console.log(pageId, argsObj, pushState);
			
			switch (pageId) {
				
				/*case constants.PAGE_OVERVIEW:
					this.curViewManager = overviewManager;
					overviewManager.showOverview(callbackComplete);
					break;*/
				
				//------------------------------------------------------
				
				case constants.PAGE_REQUEST_LIST:
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL,
							UserRole.PERMISSION_REQUEST_LIST_OWN,
							UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER,
							UserRole.PERMISSION_REQUEST_LIST_RELEASED,
							UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = requestListManager;
					groupId = argsObj.groupId || argsObj.urlArgs.groupId || 0;
					newUrl += "&groupId=" + groupId;
					requestListManager.showRequests(groupId, callbackComplete);
					break;
				
				case constants.PAGE_REQUEST_ARCHIVE:
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_LIST_ALL,
							UserRole.PERMISSION_REQUEST_LIST_OWN,
							UserRole.PERMISSION_REQUEST_LIST_ASSIGNED_DECIDER,
							UserRole.PERMISSION_REQUEST_LIST_RELEASED,
							UserRole.PERMISSION_REQUEST_LIST_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true ,null, callbackComplete);
						return false;
					}
					if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = requestListManager;
					groupId = argsObj.groupId || argsObj.urlArgs.groupId || 1;
					newUrl += "&groupId=" + groupId;
					requestListManager.showRequests(groupId, callbackComplete);
					break;
				
				case constants.PAGE_REQUEST_DETAILS:
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					requestId = argsObj.requestId || argsObj.urlArgs.requestId;
					if (!requestId) {
						this.execShowPage(constants.PAGE_REQUEST_LIST, null, pushState, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = requestDetailManager;
						requestId = parseInt(requestId);
						newUrl += "&requestId=" + requestId;
						requestDetailManager.showRequest(requestId, argsObj.isNew, callbackComplete);
					}
					argsObj.requestId = requestId;
					break;
				
				case constants.PAGE_REQUEST_ADD:
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE, UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = taskRequestEditorManager;
					taskRequestEditorManager.addRequest(callbackComplete);
					break;
				
				case constants.PAGE_REQUEST_EDIT:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_EDIT_ALL,
							UserRole.PERMISSION_REQUEST_EDIT_OWN,
							UserRole.PERMISSION_REQUEST_EDIT_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					if (!model.client.hasModule(constants.MODULE_ID_REQUESTS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					requestId = argsObj.requestId || argsObj.urlArgs.requestId;
					if (!requestId) {
						this.execShowPage(constants.PAGE_REQUEST_LIST, null, null, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = taskRequestEditorManager;
						requestId = parseInt(requestId);
						newUrl += "&requestId=" + requestId;
						taskRequestEditorManager.editRequest(requestId, callbackComplete);
					}
					argsObj.requestId = requestId;
					break;

				case constants.PAGE_REQUEST_CLONE:
					if (!authManager.hasPermission(UserRole.PERMISSION_REQUEST_CREATE,
							UserRole.PERMISSION_REQUEST_CREATE_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = taskRequestEditorManager;
					taskRequestEditorManager.cloneRequest(callbackComplete);
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_TASK_LIST:
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL,
							UserRole.PERMISSION_TASK_LIST_OWN,
							UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
						//nicht Overview, sonst ggf. Endlosschleife
						this.execShowPage(constants.PAGE_SETTINGS, null, true);
						return false;
					}
					this.curViewManager = taskListManager;
					groupId = argsObj.groupId || argsObj.urlArgs.groupId || 0;
					newUrl += "&groupId=" + groupId;
					taskListManager.showTasks(argsObj.userGroupIds, argsObj.userGroup, groupId, callbackComplete);
					break;
				
				case constants.PAGE_TASK_ARCHIVE:
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_LIST_ALL,
							UserRole.PERMISSION_TASK_LIST_OWN,
							UserRole.PERMISSION_TASK_LIST_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = taskListManager;
					groupId = argsObj.groupId || argsObj.urlArgs.groupId || 1;
					newUrl += "&groupId=" + groupId;
					taskListManager.showTasks(argsObj.userGroupIds, argsObj.userGroup, groupId, callbackComplete);
					break;
				
				case constants.PAGE_TASK_DETAILS:
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					taskId = argsObj.taskId || argsObj.urlArgs.taskId;
					if (!taskId) {
						this.execShowPage(constants.PAGE_TASK_LIST, null, true, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = taskDetailManager;
						taskId = parseInt(taskId);
						newUrl += "&taskId=" + taskId;
						taskDetailManager.showTask(taskId, argsObj.isNew, argsObj, callbackComplete);
					}
					argsObj.taskId = taskId;
					break;
				
				case constants.PAGE_TASK_ADD:
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL,
							UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = taskRequestEditorManager;
					taskRequestEditorManager.addTask(callbackComplete);
					break;
				
				case constants.PAGE_TASK_CLONE:
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_CREATE_ALL,
							UserRole.PERMISSION_TASK_CREATE_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					this.curViewManager = taskRequestEditorManager;
					taskRequestEditorManager.cloneTask(callbackComplete);
					break;
				
				case constants.PAGE_TASK_EDIT:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_EDIT_ALL,
							UserRole.PERMISSION_TASK_EDIT_OWN,
							UserRole.PERMISSION_TASK_EDIT_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					taskId = argsObj.taskId || argsObj.urlArgs.taskId;
					if (!taskId) {
						this.execShowPage(constants.PAGE_TASK_LIST, null, true, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = taskRequestEditorManager;
						taskId = parseInt(taskId);
						newUrl += "&taskId=" + taskId;
						taskRequestEditorManager.editTask(taskId, callbackComplete);
					}
					argsObj.taskId = taskId;
					break;
				
				case constants.PAGE_TASK_PROTOCOL:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_PROTOCOL_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					taskId = argsObj.taskId || argsObj.urlArgs.taskId;
					protocolId = argsObj.protocolId || argsObj.urlArgs.protocolId;
					if ((!taskId) || (!protocolId)) {
						this.execShowPage(constants.PAGE_TASK_LIST, null, true, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = protocolManager;
						taskId = parseInt(taskId);
						protocolId = parseInt(protocolId);
						newUrl += "&taskId=" + taskId + "&protocolId=" + protocolId;
						protocolManager.showTaskProtocol(taskId, protocolId, callbackComplete);
					}
					argsObj.taskId = taskId;
					break;

				case constants.PAGE_TASK_PROTOCOL_EDIT:

					//Permissions grundlegend vorhanden?
					if (!authManager.hasPermission(UserRole.PERMISSION_TASK_EXECUTE_ALL, UserRole.PERMISSION_TASK_EXECUTE_OWN,  UserRole.PERMISSION_TASK_EXECUTE_BY_TASK_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}

					taskId = argsObj.taskId || argsObj.urlArgs.taskId;
					locationId = argsObj.locationId || argsObj.urlArgs.locationId;
					formId = argsObj.formId || argsObj.urlArgs.formId;
					if ((!taskId) || (!locationId) || (!formId)) {
						this.execShowPage(constants.PAGE_TASK_LIST, null, true, null, callbackComplete);
						return;
					}
					else {
						this.curViewManager = protocolEditManager;
						taskId = parseInt(taskId);
						locationId = parseInt(locationId);
						formId = parseInt(formId);
						newUrl += "&taskId=" + taskId + "&locationId=" + locationId + "&formId=" + formId;
						protocolEditManager.editTaskProtocol(taskId, locationId, formId, callbackComplete);
					}
					argsObj.taskId = taskId;
					break;
				
				//-----------------------------------------------------
				
				case constants.PAGE_USER_LIST:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_ALL, UserRole.PERMISSION_USER_LIST_SELF)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = userListManager;
					userListManager.showUsers(callbackComplete);
					break;
				
				case constants.PAGE_USER_DETAILS:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_USER_LIST_SELF, UserRole.PERMISSION_USER_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					userId = argsObj.userId || argsObj.urlArgs.userId;
					if (!userId) {
						this.execShowPage(constants.PAGE_USER_LIST, null, true, null, callbackComplete);
						return false;
					}
					else {
						this.curViewManager = userDetailManager;
						userId = parseInt(userId);
						newUrl += "&userId=" + userId;
						userDetailManager.editUser(userId, false, callbackComplete);
					}
					argsObj.userId = userId;
					break;
				
				case constants.PAGE_USER_ADD:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_USER_CREATE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = userDetailManager;
					userDetailManager.addUser(callbackComplete);
					break;
				
				case constants.PAGE_USER_EDIT:
					
					userId = argsObj.userId || argsObj.urlArgs.userId;
					if (!userId) {
						this.execShowPage(constants.PAGE_USER_LIST, null, true, null, callbackComplete);
						return false;
					}
					
					var mayEditUser = false;
					if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_ALL)) {
						mayEditUser = true;
					}
					else {
						if (authManager.hasPermission(UserRole.PERMISSION_USER_EDIT_SELF)) {
							if (userId === model.curUserId)
								mayEditUser = true;
						}
					}
					
					if (!mayEditUser) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = userDetailManager;
					userId = parseInt(userId);
					newUrl += "&userId=" + userId;
					userDetailManager.editUser(userId, true, callbackComplete);
					argsObj.userId = userId;
					break;
				
				//---------------------------------------------------
				
				case constants.PAGE_USERGROUP_LIST:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_LIST)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = userGroupListManager;
					userGroupListManager.showUserGroups(callbackComplete);
					break;
				
				
				case constants.PAGE_USERGROUP_ADD:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_CREATE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = userGroupDetailManager;
					userGroupDetailManager.addUserGroup(callbackComplete);
					break;
				
				case constants.PAGE_USERGROUP_DETAILS:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					userGroupId = argsObj.userGroupId || argsObj.urlArgs.userGroupId;
					if (!userGroupId) {
						this.execShowPage(constants.PAGE_USERGROUP_LIST, null, true, null, callbackComplete);
						return false;
					}
					else {
						this.curViewManager = userGroupDetailManager;
						userGroupId = parseInt(userGroupId);
						newUrl += "&userGroupId=" + userGroupId;
						userGroupDetailManager.editUserGroup(userGroupId, false, callbackComplete);
					}
					argsObj.userGroupId = userGroupId;
					break;
				
				case constants.PAGE_USERGROUP_EDIT:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_TEAM_EDIT)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					userGroupId = argsObj.userGroupId || argsObj.urlArgs.userGroupId;
					if (!userGroupId) {
						this.execShowPage(constants.PAGE_USERGROUP_LIST, null, true, null, callbackComplete);
						return false;
					}
					else {
						this.curViewManager = userGroupDetailManager;
						userGroupId = parseInt(userGroupId);
						newUrl += "&userGroupId=" + userGroupId;
						userGroupDetailManager.editUserGroup(userGroupId, true, callbackComplete);
					}
					argsObj.userGroupId = userGroupId;
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_LOCATION_LIST:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_LOCATION_LIST_ALL,
							UserRole.PERMISSION_LOCATION_LIST_BY_LOCATION_TYPE)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					//keine Objekt-Typen?
					if (model.locationTypes.length === 0) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = locationManager;
					locationId = argsObj.locationId || argsObj.urlArgs.locationId;
					if (locationId)
						locationId = parseInt(locationId);
					else
						locationId = -1;
					newUrl += "&locationId=" + locationId;
					var showLocationEditorArgs = argsObj.edit || argsObj.urlArgs.edit;
					var showLocationEditor = false;
					if (showLocationEditorArgs)
						showLocationEditor = (parseInt(showLocationEditorArgs) === 1);
					locationManager.showLocationView(locationId, restoreState, showLocationEditor, callbackComplete);
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_INCIDENT_LIST:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_INCIDENT_LIST_ALL,
						UserRole.PERMISSION_INCIDENT_LIST_OWN/*,
						UserRole.PERMISSION_INCIDENT_LIST_BY_LOCATION_TYPE*/)) {//xxx
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = incidentListManager;
					incidentListManager.showIncidents(callbackComplete);
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_ADMIN:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_SYSTEM)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = adminSettingsManager;
					adminSettingsManager.showSettings(callbackComplete);
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_SETTINGS:
					
					this.curViewManager = settingsManager;
					settingsManager.showSettings(callbackComplete);
					break;
				
				//------------------------------------------------------
				
				case constants.PAGE_CLIENT:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_CLIENT_DETAILS)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = clientManager;
					clientManager.showClient(callbackComplete);
					break;
				
				case constants.PAGE_CLIENT_EDIT:
					
					if (!authManager.hasPermission(UserRole.PERMISSION_CLIENT_EDIT)) {
						this.execShowPage(constants.PAGE_OVERVIEW, null, true, null, callbackComplete);
						return false;
					}
					
					this.curViewManager = clientManager;
					clientManager.editClient(callbackComplete);
					break;
				
				//------------------------------------------------------
			}
			
			//merken
			if (pushState) {
				//extra für Chrome & Co
				if (this.isPageLoad) {
					this.rememberState(pageId, argsObj, newUrl);
					this.isPageLoad = false;
				}
				this.rememberState(pageId, argsObj, newUrl);
			}
			
			//--------------------------
			//dirty handler
			this.updateDirtyHandler();
		},
		
		//-------------------------------------------------------------

		consoleRedirect: function(target, formTargetContainer) {
			let redirectUrl;
			let requestData = {
				sessionToken: model.curSessionToken,
				appVersion: constants.RELEASE_VERSION,
				clientId: model.curClientId,
				userId: model.curUserId,
				since: model.prevFetchTimestamp
			};

			switch (target) {
				//------------------------------------------------------
				// BEGIN::Selfservice Console Bridge
				case constants.PAGE_REDIRECT_SELFSERVICE_START:
					redirectUrl = constants.API_BASE + "/selfservice/start";
					this.consoleHelper_post_hidden_form(formTargetContainer, redirectUrl, requestData);
					break;
				case constants.PAGE_REDIRECT_SELFSERVICE_NOTICES:
					redirectUrl = constants.API_BASE + "/selfservice/notices";
					this.consoleHelper_post_hidden_form(formTargetContainer, redirectUrl, requestData);
					break;
				case constants.PAGE_REDIRECT_SELFSERVICE_EXPORTS:
					redirectUrl = constants.API_BASE + "/selfservice/exports";
					this.consoleHelper_post_hidden_form(formTargetContainer, redirectUrl, requestData);
					break;
				// END::Selfservice Console Bridge
				//------------------------------------------------------
			}
		},
		consoleHelper_post_hidden_form: function(container, url, params) {
			let form = $("<form target='_blank' method='POST' style='display:none;'></form>").attr({
				action: url
			}).appendTo(document.getElementById(container));

			for (var i in params) {
				if (params.hasOwnProperty(i)) {
					$('<input type="hidden" />').attr({
						name: i,
						value: params[i]
					}).appendTo(form);
				}
			}
			form.submit();
			form.remove();
		},

		//ist diese Seite ein Formular, d.h. offene Angaben möglich?
		isFormPage: function () {
			
			switch (model.curPageId) {
				case constants.PAGE_TASK_EDIT:
				case constants.PAGE_TASK_ADD:
				case constants.PAGE_TASK_CLONE:
				case constants.PAGE_REQUEST_EDIT:
				case constants.PAGE_REQUEST_ADD:
				case constants.PAGE_REQUEST_CLONE:
				case constants.PAGE_USERGROUP_DETAILS:
				case constants.PAGE_LOCATION_LIST:
				case constants.PAGE_USER_DETAILS:
				case constants.PAGE_USER_ADD:
				case constants.PAGE_USER_EDIT:
				case constants.PAGE_USERGROUP_ADD:
				case constants.PAGE_USERGROUP_EDIT:
				case constants.PAGE_CLIENT_EDIT:
				case constants.PAGE_SETTINGS:
				case constants.PAGE_TASK_PROTOCOL_EDIT:
					return true;
			}
			return false;
		},
		
		//Dirty-Flag entfernen
		clearFormDirty: function () {
			model.isCurFormDirty = false;
		},
		//Dirty-Flag setzen
		setFormDirty: function () {
			model.isCurFormDirty = true;
		},
		
		//Dirty-Flag steuern: Handler setzen
		updateDirtyHandler: function () {

			var that = this;
			
			setTimeout(function () {
				
				//neue Form
				that.clearFormDirty();
				
				$(".form-control, .form-control2").on("change", function () {
					var el = $(this);
					if (!el.is(":visible"))
						return;
					if (el.hasClass("not-dirtying"))
						return;
					that.setFormDirty();
				});
			}, 500);
		},
		
		//-------------------------------------------------------------
		//history API + own state details
		//-------------------------------------------------------------
		
		getUrlRoot: function(){
			return this.originalHref + "#pageId=" + model.curPageId + "&clientId=" + model.curClientId;
		},

		//nur aktuelle URL verändern
		replaceUrl: function(urlSuffix){
			var url = this.getUrlRoot() + "&" + urlSuffix;
			window.history.replaceState(window.history.state, "upkeepr", url);
		},
		//gesamten History-Eintrag verändern
		replaceCurrentHistoryState: function(pageId, argsObj, url){
			history.replaceState({
				pageId: pageId,
				args: argsObj
			}, "Page " + pageId, url);
		},
		
		//Stack der Screens verwalten
		//Details auf der Seite (Tab-Wechsel)
		stateDetails: [],
		//Screens selber, abweichend (damit beeinflussbar) von window.history
		transitionStateDetails: [],
		rememberState: function (pageId, argsObj, url) {

			//console.log("push " + pageId, argsObj);
			history.pushState({
					pageId: pageId,
					args: argsObj
				},
				"Page " + pageId,
				url);

			//Infos zum Zeitpunkt des Wechsels merken
			this.transitionStateDetails.push(model.curStateDetails);

			//initial nichts merken
			this.stateDetails.push(null);
		},
		
		//unabh. von history API Details eines Screens merken (z.B. welcher Tab war aktiv)
		setStateDetails: function (details) {
			if (this.stateDetails.length)
				this.stateDetails[this.stateDetails.length - 1] = details;
		},
		
		//Details eines Screens zurücksetzen
		resetStateDetails: function () {
			model.curStateDetails = null;
		},
		
		//Browser-Zurück behandeln
		onPopState: function (event) {
			
			var that = viewManager;
			
			var state = event.state;
			if (state !== null) {

				//console.log("pop " + state.pageId, state.args);

				var screenOnTransitionDetails = that.transitionStateDetails.pop();

				//aktueller Zustand irrelvant
				that.stateDetails.pop();
				model.curStateDetails = null;
				
				//inject
				if (that.stateDetails.length)
					model.curStateDetails = that.stateDetails[that.stateDetails.length - 1];
				model.curStateDetails = model.curStateDetails || {};

				//übernahme bestimmter Daten vom Zeitpunkt des Off-Page-Wechsels
				if (screenOnTransitionDetails) {
					model.curStateDetails.scrollPosition = screenOnTransitionDetails.scrollPosition;
				}

				viewManager.showPage(state.pageId, state.args, false, true, true, false, function(){

					//Scrolling wiederherstellen
					if (model.curStateDetails) {
						that.setScrollTop(model.curStateDetails.scrollPosition);
					}

				});
			}
		},
		
		//---------------------------------------------------
		
		//Kunden-Logo aktualisieren
		updateClientDisplay: function () {
			if (model)
				if (model.client) {
					/*
					if (model.client.logoData) {
						var ex = $("#sideMenu .clientLogo").attr("src");
						if (ex !== model.client.logoData)
							$("#sideMenu .clientLogo").attr("src", model.client.logoData);
					}
					*/
					if (model.client.name)
						$("#pageHeader .clientInfo").html(model.client.name);
				}
		},
		
		//Anzeige für Daten-Aktualität aktualisieren
		updateDataLoadedDisplay: function (newCount) {
			
			var s = '';
			if (newCount > 0) {
				s += 'Zuletzt aktualisiert:<br> ' + pg.formatDateTime(new Date());
			}
			else
				s += 'Zuletzt aktualisiert:<br> ' + pg.formatDateTime(new Date());
			
			$("#lblUpdate").html(s);
		},
		
		//Account-Wechsel aktualisieren
		updateAccountDisplay: function () {
			if (model.accounts.length > 1)
				$(".menuChangeAccount").show();
			else
				$(".menuChangeAccount").hide();
		},
		
		//---------------------------------------------------
		
		//einen Hinweis anzeigen
		showNotification: function (msg, type) {
			
			if (type === undefined)
				type = constants.NOTIFICATION_INFO;
			
			toastr.options = {
				"closeButton": true,
				"debug": false,
				"newestOnTop": false,
				"progressBar": false,
				"positionClass": "toast-top-right",
				"preventDuplicates": false,
				"onclick": null,
				"showDuration": "300",
				"hideDuration": "1000",
				"timeOut": "5000",
				"extendedTimeOut": "1000",
				"showEasing": "swing",
				"hideEasing": "linear",
				"showMethod": "fadeIn",
				"hideMethod": "fadeOut"
			};
			
			switch (type) {
				case constants.NOTIFICATION_INFO:
					toastr.info(msg);
					break;
				case constants.NOTIFICATION_SUCCESS:
					toastr.success(msg);
					break;
				case constants.NOTIFICATION_WARNING:
					toastr.warning(msg);
					break;
			}
		}
		
		
	};
	
	window.viewManager = viewManager;
}());
