(function () {
	
	//----------------------------------------------------------------
	//Protokoll-Formular
	//----------------------------------------------------------------
	
	/*global
	constants:true,
	TaskType:true,
	ProtocolFormField:true,
	LocationField:true,
	Signal:true,
	Incident:true,
	Version:true,
	viewManager:true,
	ProtocolDataItem:true
	*/
	
	'use strict';
	
	var ProtocolForm = {

		protocolFormPrototype: {
			
			fromObj: function (t) {
				this.id = parseInt(t.id, 10);
				this.createdOn = pg.parseDate(t.createdOn);
				this.createdBy = parseInt(t.createdBy, 10);
				this.changedOn = pg.parseDate(t.changedOn);
				this.changedBy = parseInt(t.changedBy, 10);

				this.formId = parseInt(t.formId, 10);
				this.clientId = parseInt(t.clientId, 10);
				this.version = parseInt(t.version, 10);
				this.options = t.options;
				this.name = t.name;
				
				this.requiredAppVersion = null;
				if (t.requiredAppVersion)
					this.requiredAppVersion = t.requiredAppVersion;
				
				return this;
			},
			
			serialize: function () {
				
				var o = {};
				
				o.id = this.id;
				o.createdOn = pg.buildDate(this.createdOn);
				o.createdBy = this.createdBy;
				o.changedOn = pg.buildDate(this.changedOn);
				o.changedBy = this.changedBy;

				o.formId = this.formId;
				o.clientId = this.clientId;
				o.version = this.version;
				o.options = this.options;
				o.name = this.name;
				o.locationType = this.locationType;
				
				o.requiredAppVersion = this.requiredAppVersion;
				
				return o;
			},
			
			//---------------------------------------------------------------
			//Felder im Protokoll (gemäß Prüfungskonfiguration)
			
			addField: function (o) {
				if (this.fields === undefined)
					this.fields = [];
				
				//shallow copy!
				var a = [];
				for(var i = 0; i < this.fields.length; i++)
					a.push($.extend({}, this.fields[i]));
				a.push(o);
				this.fields = a;
			},
			hasFormFields: function (mustHaveDisplayModeDependingOnConfig) {

				if (mustHaveDisplayModeDependingOnConfig === undefined)
					mustHaveDisplayModeDependingOnConfig = false;

				if (this.fields === undefined)
					return false;
				if (mustHaveDisplayModeDependingOnConfig){
					for (var i=0; i<this.fields.length; i++){
						if (this.fields[i].displayMode === ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD)
							return true;
					}
					return false;
				}
				else {
					return (this.fields.length > 0);
				}
			},
			getFormField: function (formFieldId) {

				formFieldId = parseInt(formFieldId);
				for(var i = 0; i < this.fields.length; i++)
					if (this.fields[i].id === formFieldId)
						return this.fields[i];

				return null;
			},
			getFormFields: function(){
				return this.fields;
			},

			//----------------------------------------------------------

			hasOption: function(optionName){
				if (!this.options)
					return false;

				optionName = optionName.toLowerCase();
				var op = this.options.split("#");
				for (var i=0; i<op.length; i++){
					if (op[i].toLowerCase() === optionName)
						return true;
				}
				return false;
			},
			
			//---------------------------------------
			//neue Fassung via DB (generierte Formulare)

			getColorData2: function(formFieldId, fieldValue){
				var ff = this.getFormField(formFieldId);
				if (!ff) {
					return "";
				}
				return ff.getColorData(fieldValue);
			},
			getIncidentType2: function (formFieldId, fieldValue) {
				var ff = this.getFormField(formFieldId);
				if (ff)
					return ff.getIncidentType(fieldValue);
				return 0;
			},

			//---------------------------------------
			//Vorkommnis-Daten anzeigen

			getProtocolIncidentData: function (protocol) {

				var results = [];

				for (var i=0; i<protocol.incidents.length; i++){
					var inc = protocol.incidents[i];
					var formField = this.getFormField(inc.fieldId);

					var name = formField.name;
					var val = inc.fieldValue || "n.n. (altes Protokoll)";

					var colorClass = this.getColorData2(inc.fieldId, val);

					results.push({
						name: name,
						type: constants.TYPE_STRING,
						value: val,
						colorClass: colorClass,
						formField: formField
					});

					if (inc.description)
						if (inc.description.length){
							results.push({
								name: "Bemerkungen",
								type: constants.TYPE_TEXT,
								value: inc.description,
								colorClass: null,
								formField: formField
							});
						}
				}

				return results;
			},

			//-----------------------------------------------------
			//Protokolldaten anzeigen

			//auslesen von gespeicherten Werten
			getDataVal: function(s){
				var pos = s.indexOf(":");
				if (pos < 0)
					return s;
				return s.substring(pos+1);
			},

			//für Anzeige zusammentragen
			getProtocolData: function (protocol) {

				var results = [],
					colorClass,
					i,
					val;

				for (i = 0; i < protocol.protocolData.length; i++) {

					var pdi = protocol.protocolData[i];
					colorClass = "";

					var formField = this.getFormField(pdi.formFieldId);
					if (!formField) {
						if (pdi.formFieldId === constants.PROTOCOL_FORMFIELD_NOTES) {
							results.push({
								name: "Bemerkungen",
								formFieldId: pdi.formFieldId,
								type: constants.TYPE_TEXT,
								value: pdi.valueString,
								colorClass: null,
								formField: formField
							});
						}
					}
					else {

						//Wert übernehmen
						val = "";
						switch (formField.type) {

							case LocationField.FIELD_TYPE_STRING:
							case LocationField.FIELD_TYPE_LIST:
							case LocationField.FIELD_TYPE_LIST_OR_STRING:
							case LocationField.FIELD_TYPE_LONG_TEXT:
							case LocationField.FIELD_TYPE_LIST_BUTTONS:
								colorClass = this.getColorData2(pdi.formFieldId, pdi.valueString);
								val = pdi.valueString;
								break;

							case LocationField.FIELD_TYPE_INT:
								val = pdi.valueInt;
								break;

							case LocationField.FIELD_TYPE_BOOL:
								val = (pdi.valueInt === 1) ? "ja" : "nein";
								break;

							case LocationField.FIELD_TYPE_FLOAT:
								val = pdi.valueFloat;
								break;

							case LocationField.FIELD_TYPE_DATE:
								val = pg.formatDate(pdi.valueDate, true);
								break;

							case LocationField.FIELD_TYPE_TIME:
								//ist aus hist. Gründen ein String.
								/*if (pdi.valueDate)
									val = pg.formatTime(pdi.valueDate, true);
								else*/
									val = pdi.valueString;
								break;

							default:
								val = pdi.valueString;
								break;
						}

						//ggf. Suffix
						if ((val !== null) && (val !== ""))
							if (formField.suffix)
								val += " " + formField.suffix;

						results.push({
							name: formField.name,
							formFieldId: pdi.formFieldId,
							type: constants.TYPE_STRING,
							value: val,
							colorClass: colorClass,
							formField: formField
						});
					}
				}

				return results;
			},
			
			//---------------------------------------------------
			
			//zugeordnete Auftragstypen holen
			getTaskTypes: function () {
				var a = [];
				
				var allTt = TaskType.getAllTaskTypes();
				for(var i = 0; i < allTt.length; i++) {
					var tt = allTt[i];
					if (tt.hasFormId(this.formId))
						a.push(tt);
				}
				
				return pg.getUnique(a);
			},

			//---------------------------------------------------

			getIncident: function (index, type, desc, fieldValue) {
				if (!this.location)
					return null;
				return Incident.createIncident(model.curClientId,
					model.curUserId,
					this.task.taskId,
					this.location.locationId,
					-1,
					this.formId,
					this.formFields[index].id,//index,
					type,
					desc,
					fieldValue);
			},
			getSignal: function (index, type) {
				if (!this.location)
					return null;
				return Signal.createSignal(model.curClientId,
					model.curUserId,
					this.task.taskId,
					this.location.locationId,
					-1,
					this.formId,
					-1,
					this.formFields[index].id,//index,
					type,
					1);
			},

			//---------------------------------------------------------------------------------------
			//---------------------------------------------------------------------------------------
			//Zentralisierte Code-Basis für alle Protokoll-Forms (statt individueller HTML-Dateien)

			//initialisieren und Status anwenden
			formEl: null,
			elementCount: 0,
			elementData: [],
			formFields: [],
			attachments: [],
			signals: [],
			curCategory: "",
			formContainer: null,
			location: null,
			task: null,
			tabIndex: 1,

			isApp: true,

			initProtocol: function(formContainer, protocol, task, location){

				var that = this,
					i,
					formField;

				//-------------------------------

				//init / reset
				this.formContainer = formContainer;
				this.formContainer.setProtocolDirty(false);
				this.elementCount = 0;
				this.elementData = [];
				this.attachments = [];
				this.signals = [];
				this.curCategory = "";
				this.formFields = [];
				that.location = location;
				that.task = task;
				this.tabIndex = 1;

				this.isApp = formContainer.isApp();

				this.formEl = $("#protocolContainer");
				$("#notes", this.formEl).val("");
				//$("#pruefungBox", this.formEl).html("");

				//-------------------------------

				//zugeordnete FormFields anzeigen
				this.formFields = this.getFormFields();
				this.formFields.sort(function(a,b){
					return (a.orderIndex - b.orderIndex);
				});
				var ffs = [];
				var s = "";
				for (i=0; i<this.formFields.length; i++){
					formField = this.formFields[i];

					//je Feld: Prüfungskonfiguration erforderlich?
					var addEl = true;
					if (formField.displayMode === ProtocolFormField.DISPLAY_MODE_DEPENDING_ON_LOCATION_FORM_FIELD){
						addEl = location.hasFormField(formField.id);
					}
					if (addEl) {
						s += this.addElement(this.elementCount++, formField);
						ffs.push(formField);
					}
				}

				s += this.finalizeElements();
				$("#pruefungBox", this.formEl).html(s);

				//merken, was wirklich verwendet wird
				this.formFields = ffs;

				//-------------------------------

				//Fehler-Boxen verstecken
				$(".button-bar.buttonBarItem", this.formEl).removeClass("hasSignalBoxYellow").removeClass("hasSignalBoxRed");
				$("select", this.formEl).removeClass("hasSignalBoxYellow").removeClass("hasSignalBoxRed");
				$(".descBox", this.formEl).hide();
				$(".signalBox", this.formEl).hide();

				//nichts anzeigen
				this.showValidation(false);

				//----------------------------------------------------------
				//Eventhandlers

				//Button
				$("#formProtocolEdit .button-bar.buttonBarItem .button", that.formEl).off("click").on("click", function(e){

					//identify
					that.setProtocolDirty();
					var parentId = e.currentTarget.parentNode.id;
					var index = parseInt(parentId.split("_")[1], 10);

					var val = ProtocolForm.decodeHtml($(e.currentTarget).html());

					//de-selektieren
					if (that.elementData[index] === val){
						that.elementData[index] = null;
					}
					else {

						//merken
						that.elementData[index] = val;
					}

					that.setButtonValue($(e.currentTarget), parentId, index, that.elementData[index]);

					//de-selektieren?
					if (that.elementData[index] === null){
						$(e.currentTarget).blur();
					}
				});

				//Signale umschalten
				$(".signalBox .signalFixed", that.formEl).off("click").on("click", function(e){

					var btnIndex = parseInt($(e.currentTarget).attr("data-index"), 10);
					if (isNaN(btnIndex))
						return;
					var signal = that.signals[btnIndex];
					signal.defect = !signal.defect;
					if (signal.defect)
						$(this).removeClass("active");
					else
						$(this).addClass("active");
					that.setProtocolDirty();
				});
				$(".signalBox .signalNotify", that.formEl).off("click").on("click", function(e){

					var btnIndex = parseInt($(e.currentTarget).attr("data-index"), 10);
					if (isNaN(btnIndex))
						return;
					var signal = that.signals[btnIndex];
					signal.notify = !signal.notify;
					if (signal.notify)
						$(this).addClass("active");
					else
						$(this).removeClass("active");
					that.setProtocolDirty();
				});

				//speichern
				setTimeout(function(){
					$(".btnSaveProtocol", that.formEl).off("click").on("click", function(){

						if (!viewManager.setButtonBlocking($(this)))
							return false;

						//commit ohne ajax
						that.callSubmit(false);
					});
				},500);
				//Absenden
				$(".btnSubmitProtocol", that.formEl).off("click").on("click", function(e){

					if (!viewManager.setButtonBlocking($(this)))
						return false;

					//noch nix ausgefüllt?
					if (!that.validate(true)){
						viewManager.updateButtons();
						e.preventDefault();
						return;
					}

					//mit ajax
					that.callSubmit(true);
				});

				$("#btnAllGood", that.formEl).off("click").on("click", function(){

					var parent,
						index,
						button;

					that.setProtocolDirty();
					//alle Prüfungswerte auf OK setzen
					for (i=0; i<that.formFields.length; i++){

						formField = that.formFields[i];

						switch (formField.type){

							case LocationField.FIELD_TYPE_LIST_BUTTONS:
								//nichts überschreiben, aber ggf. okValue setzen
								if ((!that.elementData[i]) && (that.formFields[i].okValue)){

									//Ok-Wert suchen
									index = -1;
									var options = pg.splitNonEmpty(formField.options, "#");
									for (var j=0; j<options.length; j++){
										if (options[j] === that.formFields[i].okValue){
											index = j;
											break;
										}
									}
									//korrekt?
									if (index >= 0) {
										that.elementData[i] = that.formFields[i].okValue;
										var parentId = "bb_" + i;
										button = $("#" + parentId + " a[data-index=\"" + index + "\"]");
										that.setButtonValue(button, parentId, i, that.elementData[i]);
									}
								}
								break;

							case LocationField.FIELD_TYPE_LIST:
								//nichts überschreiben, aber ggf. okValue setzen
								if ((!that.elementData[i]) && (that.formFields[i].okValue)){
									that.elementData[i] = that.formFields[i].okValue;
									var el = $("#input_int_" + i, that.formEl);
									el.val(that.elementData[i]);

									if (!that.isApp){
										el.trigger("change.select2");
									}
								}
								break;
						}
					}
				});

				//alles zuruck
				$("#btnReset", that.formEl).off("click").on("click", function(){

					that.setProtocolDirty();
					var os = that.formContainer.getOverlayService();
					var msg = 'Sind Sie sicher, dass Sie das Protokoll zur&uuml;cksetzen m&ouml;chten? Alle Eingaben gehen verloren...';
					if (!that.isApp){
						msg = "<strong>Möchten Sie das Protokoll wirklich zurücksetzen?</strong><br />Alle Eingaben gehen verloren.";
					}
					os.showConfirm(msg, function(res){
						if(res) {
							that.attachments = [];
							that.initProtocol(that.formContainer, null, that.task, that.location);
							that.formContainer.onProtocolInitComplete();
						}
					}, 'fa fa-warning', 'Ja', 'Nein');
				});

				setTimeout(function(){

					//warum auch immer erst nach Timeout?! Wegen Angular ngIf etc.

					//Bild hinzufügen
					$(".btnAddPicture", that.formEl).off("click").on("click", function(e){
						that.setProtocolDirty();
						that.formContainer.addPicture(e, function(){
							that.formContainer.updateImages();
						});
					});


					$("#notes", that.formEl).off("change").on("change", function(){
						that.setProtocolDirty();
					});

					//Time-Picker
					$('.time-picker', that.formEl).off("dp.change").on("dp.change", function(e){

						//forbidden?
						if (that.prevTimeBoxTs){
							var now = new Date().getTime();
							var delta = now - that.prevTimeBoxTs;
							if (delta < 1000)
								return false;
						}

						var parentId = e.currentTarget.parentNode.id;
						var index = parseInt(parentId.split("_")[1], 10);

						var d = new Date(e.date);
						var val = pg.forceLength(d.getHours(), 2) + ":" + pg.forceLength(d.getMinutes(), 2);
						that.elementData[index] = val;

						that.setProtocolDirty();
					});

					//re-format
					$(".protocolInputField", that.formEl)/*.off("change")*/.on("change", function(e){

						var formFieldId = parseInt($(this).attr("data-formFieldId"));
						var ff = that.getFormField(formFieldId);
						var val = $(this).val();

						var parentId = e.currentTarget.parentNode.id;
						var index = parseInt(parentId.split("_")[1], 10);

						switch (ff.type) {

							case LocationField.FIELD_TYPE_INT:
							case LocationField.FIELD_TYPE_FLOAT:

								//comma vs dot
								val = pg.replace(val+"", ",", ".");

								val = ff.getFormattedValue(val);
								$(this).val(val);

								that.elementData[index] = pg.replace(val + "", ",", ".");

								break;

							case LocationField.FIELD_TYPE_LIST:
								val = ff.getFormattedValue(val);
								$(this).val(val);
								
								that.elementData[index] = val;
								break;
								
							default:
								val = ff.getFormattedValue(val);
								$(this).val(val);

								that.elementData[index] = val;
								break;

							case LocationField.FIELD_TYPE_BOOL:
								val = $(this).prop("checked");
								that.elementData[index] = val ? 1 : 0;
								break;

							case LocationField.FIELD_TYPE_DATE:
								val = $(this).val();
								var isGermanFormat = !that.isApp;
								var d = pg.parseDate(val, isGermanFormat);
								d.setHours(12);
								that.elementData[index] = d;
								break;

							case LocationField.FIELD_TYPE_RUNNING_TIME:
								var curCounter = (that.elementData[index] || "::").split(":");
								var arrayIndex = -1;
								var el = $(this);
								val = parseInt(el.val());
								if (el.hasClass("days")) {
									arrayIndex = 0;
									val = Math.max(0, val);
								}
								if (el.hasClass("hours")) {
									arrayIndex = 1;
									val = Math.min(23, Math.max(0, val));
								}
								if (el.hasClass("minutes")) {
									arrayIndex = 2;
									val = Math.min(59, Math.max(0, val));
								}
								el.val(val);
								curCounter[arrayIndex] = val;
								that.elementData[index] = curCounter.join(":");
								break;
						}

						//Vorkommnis?
						switch (ff.type){
							default:
								break;

							case LocationField.FIELD_TYPE_LIST:
								that.setSelectValue(ff, val, index);
								break;
						}

						that.setProtocolDirty();
					});

				}, 500);

				//fix auto-submit issues
				setTimeout(function(){
					that.fixProtocolForms();
				}, 1000);

				//-------------------------------------------------------------------------

				//neues Protokoll oder Wiederherstellung?
				if (protocol === null){

					var j,
						button,
						parentId2;

					//alles auf neutral
					for (i=0; i<this.formFields.length; i++){

						var ff = this.formFields[i];
						that.elementData[i] = null;

						//special: Default
						if (ff.defaultValue){

							that.elementData[i] = ff.defaultValue;

							switch(ff.type){

								case LocationField.FIELD_TYPE_LIST:
								case LocationField.FIELD_TYPE_LIST_OR_STRING:
									$("select[data-formFieldId='" + ff.id + "']", this.formEl).val(that.elementData[i]);
									break;

								case LocationField.FIELD_TYPE_LIST_BUTTONS:
									var index = ff.getOptionIndex(ff.defaultValue);
									if (index >= 0) {
										parentId2 = "bb_" + i;
										button = $("#" + parentId2 + " a[data-index=\"" + index + "\"]");
										that.setButtonValue(button, parentId2, i, that.elementData[i]);
									}
									break;

								case LocationField.FIELD_TYPE_STRING:
									$("#input_string_" + i, this.formEl).val(this.elementData[i]);
									break;
								case LocationField.FIELD_TYPE_LONG_TEXT:
									$("#input_text_" + i, this.formEl).val(this.elementData[i]);
									break;

								case LocationField.FIELD_TYPE_INT:
									this.elementData[i] = parseInt(this.elementData[i]);
									$("#input_int_" + i, this.formEl).val(formField.getFormattedValue(this.elementData[i]));
									break;
								case LocationField.FIELD_TYPE_BOOL:
									this.elementData[i] = parseInt(this.elementData[i]);
									$("#input_bool_" + i, this.formEl).prop("checked", (this.elementData[i] === 1));
									break;
								case LocationField.FIELD_TYPE_FLOAT:
									this.elementData[i] = parseFloat(this.elementData[i]);
									$("#input_float_" + i, this.formEl).val(formField.getFormattedValue(this.elementData[i]));
									break;
								case LocationField.FIELD_TYPE_DATE:
									this.elementData[i] = pg.parseDate(this.elementData[i]);
									if (this.elementData[i]) {
										var d = this.elementData[i].getFullYear() + "-" + pg.forceLength((this.elementData[i].getMonth()+1), 2) + "-" + pg.forceLength(this.elementData[i].getDate(), 2);
										$("#input_date_" + i, this.formEl).val(d);
									}
									break;
								case LocationField.FIELD_TYPE_TIME:
									$("#input_time_" + i, this.formEl).val(this.elementData[i]);
									break;
								case LocationField.FIELD_TYPE_RUNNING_TIME:
									if (this.elementData[i]){
										var curCounter = (this.elementData[i] || "::").split(":");
										if (curCounter[0])
											$("#input_counter_days_" + i, this.formEl).val(curCounter[0]);
										if (curCounter[1])
											$("#input_counter_hours_" + i, this.formEl).val(curCounter[1]);
										if (curCounter[2])
											$("#input_counter_minutes_" + i, this.formEl).val(curCounter[2]);
									}
									break;

							}
						}
						else{

							switch(ff.type){

								case LocationField.FIELD_TYPE_LIST_BUTTONS:
									//List-Buttons setzen
									var op = ff.getOptions();
									parentId2 = "bb_" + i;
									//Alle Buttons dieses Elementes setzen
									for (j=0; j<op.length; j++) {
										button = $("#" + parentId2 + " a[data-index=\"" + j + "\"]");
										that.setButtonValue(button, parentId2, i, that.elementData[i]);
									}
									break;
							}

						}

						//keine Vorkommnisse
						$("#m" + i, this.formEl).val("");
						$(".signalBox .signalFixed", this.formEl).removeClass("active");
						$(".signalBox .signalNotify", this.formEl).removeClass("active");
					}
				}
				else{

					this.attachments = protocol.attObj || [];

					//de-serialize & anwenden
					that.applyProtocolData(protocol);
					that.formContainer.updateImages();
				}

				viewManager.updateButtons();
			},

			//---------------------------------------------------------------------------------------

			//Protokoll wurde verändert
			setProtocolDirty: function () {
				this.formContainer.setProtocolDirty(true);
			},
			clearProtocolDirty: function () {
				this.formContainer.setProtocolDirty(false);
			},

			//---------------------------------------------------------------------------------------

			finalizeElements: function(){

				var s = '';

				if (this.isApp) {

					if (this.hasOpenCategory) {
						s += '</div>' +
							'	</div>';
						this.hasOpenCategory = false;
					}
				}

				return s;
			},

			hasOpenCategory: false,
			addElement: function(index, formField){

				var that = this;

				var i,
					opt,
					options;

				//UPK-1329: z.Zt. keine dynamischen Foto-Inputs einblenden
				if ((formField.category) && ((formField.category === "Fotos") || (formField.category === "Bemerkungen")))
					return "";

				//Inhalte vorbereiten
				this.elementData.push(null);
				this.signals.push({
					defect: true,
					notify: false
				});

				var s = '';

				//Kategorie?
				if ((formField.category) && (formField.category !== this.curCategory)){
					this.curCategory = formField.category;
					if (this.curCategory.length) {
						
						//Darstellungsform?
						if (this.isApp) {
							s += '<div class="item item-divider categoryItem">' + this.curCategory + '</div>';
						}
						else{

							if (this.hasOpenCategory) {
								s += '</div>' +
									'	</div>';
								this.hasOpenCategory = false;
							}

							//UPK-1329: z.Zt. keine dynamischen Foto-Inputs einblenden
							if ((this.curCategory !== "Fotos") && (this.curCategory !== "Bemerkungen")){

								if (index > 0) {
									s += '<hr class="m0">';
								}

								s += '<div class="protocol-category-container center-block">' +
									'<div class="form-body">' +
									'<h4 class="text-primary mb20">' + this.curCategory + '</h4>';

								this.hasOpenCategory = true;
							}
						}
					}
				}
				else{

					if (!this.isApp) {

						if (index === 0) {
							s += '<hr class="m0">';

							s += '<div class="protocol-category-container center-block">' +
								'<div class="form-body">' +
								'<h4 class="text-primary mb20">' + this.curCategory + '</h4>';

							this.hasOpenCategory = true;
						}

					}

				}

				
				//Darstellungsform?
				if (this.isApp) {
					s += '<div id="sectionHeader_' + index + '" class="item item-header formItem"><span class="errorIcon"></span>' + formField.name;
					if (formField.isRequired === 1)
						s += '<span class="required">*</span>';
					s += '</div>';
					s += '<div id="sectionContent_' + index + '" class="list card">';
				}
				else{
					
					s += '<div id="sectionHeader_' + index + '" class="form-group">' +
						'   <label class="control-label" for="">' +
						'  		<span class="errorIcon"></span>' + formField.name;
					if (formField.isRequired === 1)
						s += '	<span class="required" aria-required="true">*</span>';
					s += '	</label>';
				}
			
				switch (formField.type){
					/*jshint -W086 */
					case LocationField.FIELD_TYPE_LIST_BUTTONS:
					default:
						
						options = pg.splitNonEmpty(formField.options, "#");
						
						if (this.isApp) {
							s += '	<div id="bb_' + index + '" class="button-bar buttonBarItem">';
							
							for(i = 0; i < options.length; i++) {
								opt = options[i];
								
								s += '	  <a class="button flatButton" data-index="' + i + '">' + ProtocolForm.encodeHtml(opt) + '</a>';
							}
							
							s += '	</div>';
							s += '	<div class="item descBox">';
							s += '		<textarea id="m' + index + '" tabIndex="' + (this.tabIndex++) + '"></textarea>';
							s += '	</div>';
							s += '	<div class="item signalBox">';
							s += '		<div class="signalInnerBox">';
							s += '			<a data-index="' + index + '" class="button circleButton signalFixed" data-action="status">';
							s += '				<i class="icon fa fa-wrench smallIconLeft"></i>';
							s += '				<div class="button button-small circleButton signalFixed" data-action="status"><i class="icon fa fa-check smallIconLeft"></i></div>';
							s += '			</a>';
							s += '			<a data-index="' + index + '" class="button circleButton signalNotify" data-action="notify"><i class="icon ion-flag smallIconRight"></i></a>';
							s += '		</div>';
							s += '	</div>';
						}
						else{
						
							s += '<div id="bb_' + index + '" class="btn-group btn-group-justified button-bar buttonBarItem">';
							
							for(i = 0; i < options.length; i++) {
								opt = options[i];
								
								s += '	  <a href="javascript:;" class="btn btn-default button" data-index="' + i + '" title="' + ProtocolForm.encodeHtml(opt) + '">' + ProtocolForm.encodeHtml(opt) + '</a>';
							}
							s += '</div>';
							
							s += '	<div class="item descBox">';
							s += '		<textarea id="m' + index + '" tabIndex="' + (this.tabIndex++) + '" rows="3" onpaste="pg.filterPasteText(event)" class="form-control autosizeme"></textarea>';
							s += '	</div>';
							s += '	<div class="item signalBox">';
							s += '		<div class="signalInnerBox">';
							s += '			<a data-index="' + index + '" class="button circleButton signalFixed" data-action="status">';
							s += '				<i class="icon fa fa-wrench smallIconLeft"></i>';
							s += '				<div class="button button-small circleButton signalFixed" data-action="status"><i class="icon fa fa-check smallIconLeft"></i></div>';
							s += '			</a>';
							s += '			<a data-index="' + index + '" class="button circleButton signalNotify" data-action="notify"><i class="icon fa fa-flag smallIconRight"></i></a>';
							s += '		</div>';
							s += '	</div>';
							
						}
						break;

					case LocationField.FIELD_TYPE_STRING:
						
						if (this.isApp) {
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_string_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"/>';
						}
						else{
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="form-control protocolInputField" id="input_string_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"/>';
						}
						break;

					case LocationField.FIELD_TYPE_LONG_TEXT:
						
						if (this.isApp) {
							s += '	<textarea rows="6" tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_text_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"></textarea>';
						}
						else{
							
							s += '	<textarea rows="3" tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" onpaste="pg.filterPasteText(event)" class="protocolInputField form-control autosizeme" id="input_text_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"></textarea>';
						}
						break;

					case LocationField.FIELD_TYPE_INT:
						
						if (this.isApp) {
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9]*" inputmode="numeric" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_int_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"/>';
						}
						else{
							
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="protocolInputField form-control numeric" id="input_int_' + index + '" ';
							if (formField.suffix || formField.placeholder)
								s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
							s += '"/>';
						}
						break;

					case LocationField.FIELD_TYPE_FLOAT:
						switch (this.formContainer.getOs()){
							case constants.OS_IOS:
								s += '	<input tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="protocolInputField numeric" id="input_float_' + index + '" ';
								break;
							case constants.OS_ANDROID:
								s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9\.]*" data-formFieldId="' + formField.id + '" class="protocolInputField numeric" id="input_float_' + index + '" ';
								break;
							case constants.OS_DESKTOP:
								s += '	<input tabIndex="' + (this.tabIndex++) + '" type="text" data-formFieldId="' + formField.id + '" class="protocolInputField form-control numeric" id="input_float_' + index + '" ';
								break;
						}

						if (formField.suffix || formField.placeholder)
							s += ' placeholder="' + (formField.suffix || formField.placeholder) + ' ';
						s += '"/>';
						break;

					case LocationField.FIELD_TYPE_LIST:
						
						if (this.isApp) {
							s += '	<select tabIndex="' + (this.tabIndex++) + '" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_int_' + index + '">';
							s += '      <option value="" disabled selected style="display:none;">Bitte w&auml;hlen...</option>';
							options = pg.splitNonEmpty(formField.options, "#");
							for(i = 0; i < options.length; i++) {
								opt = options[i];
								s += '	  <option>' + ProtocolForm.encodeHtml(opt) + '</option>';
							}
							s += '	</select>';
							s += '	<div class="item descBox">';
							s += '		<textarea id="m' + index + '" tabIndex="' + (this.tabIndex++) + '"></textarea>';
							s += '	</div>';
							s += '	<div class="item signalBox">';
							s += '		<div class="signalInnerBox">';
							s += '			<a data-index="' + index + '" class="button circleButton signalFixed" data-action="status">';
							s += '				<i class="icon fa fa-wrench smallIconLeft"></i>';
							s += '				<div class="button button-small circleButton signalFixed" data-action="status"><i class="icon fa fa-check smallIconLeft"></i></div>';
							s += '			</a>';
							s += '			<a data-index="' + index + '" class="button circleButton signalNotify" data-action="notify"><i class="icon ion-flag smallIconRight"></i></a>';
							s += '		</div>';
							s += '	</div>';
						}
						else{
							
							s += '	<select tabIndex="' + (this.tabIndex++) + '" data-formFieldId="' + formField.id + '" class="protocolInputField form-control select2" id="input_int_' + index + '">';
							s += '      <option value="" disabled selected style="display:none;">Bitte w&auml;hlen...</option>';
							options = pg.splitNonEmpty(formField.options, "#");
							for(i = 0; i < options.length; i++) {
								opt = options[i];
								s += '	  <option>' + ProtocolForm.encodeHtml(opt) + '</option>';
							}
							s += '	</select>';
	
							s += '	<div class="item descBox">';
							s += '		<textarea id="m' + index + '" tabIndex="' + (this.tabIndex++) + '" rows="3" onpaste="pg.filterPasteText(event)" class="form-control autosizeme"></textarea>';
							s += '	</div>';
							s += '	<div class="item signalBox">';
							s += '		<div class="signalInnerBox">';
							s += '			<a data-index="' + index + '" class="button circleButton signalFixed" data-action="status">';
							s += '				<i class="icon fa fa-wrench smallIconLeft"></i>';
							s += '				<div class="button button-small circleButton signalFixed" data-action="status"><i class="icon fa fa-check smallIconLeft"></i></div>';
							s += '			</a>';
							s += '			<a data-index="' + index + '" class="button circleButton signalNotify" data-action="notify"><i class="icon fa fa-flag smallIconRight"></i></a>';
							s += '		</div>';
							s += '	</div>';
						}
						break;

					case LocationField.FIELD_TYPE_TIME:
						
						if (this.isApp) {
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="time" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_time_' + index + '" >';
						}
						else{
							
							s += '<div id="timebox_' + index + '" class="input-group date time-picker">' +
								'	<input id="input_time_' + index + '" tabIndex="' + (this.tabIndex++) + '" type="text" readonly data-formFieldId="' + formField.id + '" class="protocolInputField form-control">' +
								'	<span class="input-group-btn input-group-addon">' +
								'		<button class="btn btn-default" type="button">' +
								'			<i class="glyphicon glyphicon-time"></i>' +
								'		</button>' +
								'		<button type="button" class="btn btn-default btnResetTime" data-formFieldId="' + formField.id + '">' +
								'			<i class="fa fa-times"></i>' +
								'		</button>' +
								'	</span>' +
								'</div>';
						}
						break;

					case LocationField.FIELD_TYPE_BOOL:
						
						if (this.isApp) {
							s += '<label id="booleanbox_' + index + '" class="mt-checkbox mt-checkbox-single mt-checkbox-outline" for="input_bool_' + index + '">';
							s += '<input tabIndex="' + (this.tabIndex++) + '" id="input_bool_' + index + '" data-formFieldId="' + formField.id + '" type="checkbox" class="protocolInputField checkbox form-control2"';
							if (formField.defaultValue === "1")
								s += ' checked';
							s += '>';
							s += '<span></span></label>';
						}
						else {

							s += '<br/>';
							s += '<label id="booleanbox_' + index + '" class="mt-checkbox mt-checkbox-single mt-checkbox-outline" for="input_bool_' + index + '">';
							s += '<input tabIndex="' + (this.tabIndex++) + '" id="input_bool_' + index + '" data-formFieldId="' + formField.id + '" type="checkbox" class="protocolInputField group-checkable form-control2"';
							if (formField.defaultValue === "1")
								s += ' checked';
							s += '>';
							s += '<span></span></label>';
						}
						break;

					case LocationField.FIELD_TYPE_DATE:
						
						if (this.isApp) {
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="date" data-formFieldId="' + formField.id + '" class="protocolInputField" id="input_date_' + index + '" >';
						}
						else{
							
							s += '<div id="datebox_' + index + '" class="input-group date date-picker" data-date-format="dd.mm.yyyy">' +
								'   <input id="input_date_' + index + '" data-formFieldId="' + formField.id + '" tabIndex="' + (this.tabIndex++) + '" type="text" class="protocolInputField form-control" readonly>' +
								'   <span class="input-group-btn">' +
								'       <button class="btn btn-default" type="button"><i class="fa fa-calendar"></i></button>' +
								'		<button type="button" class="btn btn-default btnResetDate" data-formFieldId="' + formField.id + '">' +
								'			<i class="fa fa-times"></i>' +
								'		</button>' +
								'   </span>' +
								'</div>';
						}
						break;

					case LocationField.FIELD_TYPE_RUNNING_TIME:
						
						if (this.isApp) {
							s += '<div id="counterBox_' + index + '" class="flex flex_center fgBlack">';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9]*" inputmode="numeric" data-formFieldId="' + formField.id + '" class="protocolInputField protocolInputFieldFit days" id="input_counter_days_' + index + '" placeholder="Tage" min="0"/>';
							s += ':';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9]*" inputmode="numeric" data-formFieldId="' + formField.id + '" class="protocolInputField protocolInputFieldFit hours" id="input_counter_hours_' + index + '" placeholder="Stunden" min="0" max="23"/>';
							s += ':';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9]*" inputmode="numeric" data-formFieldId="' + formField.id + '" class="protocolInputField protocolInputFieldFit minutes" id="input_counter_minutes_' + index + '" placeholder="Minuten" min="0" max="59"/>';
							s += '</div>';
						}
						else{
							
							s += '<div id="counterBox_' + index + '" class="flex flex_center fgBlack">';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9\.]*" data-formFieldId="' + formField.id + '" class="form-control protocolInputField protocolInputFieldFit days" id="input_counter_days_' + index + '" placeholder="Tage" min="0"/>';
							s += ':';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9\.]*" data-formFieldId="' + formField.id + '" class="form-control protocolInputField protocolInputFieldFit hours" id="input_counter_hours_' + index + '" placeholder="Stunden" min="0" max="23"/>';
							s += ':';
							s += '	<input tabIndex="' + (this.tabIndex++) + '" type="number" pattern="[0-9\.]*" data-formFieldId="' + formField.id + '" class="form-control protocolInputField protocolInputFieldFit minutes" id="input_counter_minutes_' + index + '" placeholder="Minuten" min="0" max="59"/>';
							s += '</div>';
						}
						break;

					case LocationField.FIELD_TYPE_FOTO:
						/*if (this.isApp){
							//nicht mehr in v1!
						}
						else{
							//file upload
							s += '<div class="mb15">';

							//uploader
							s += '<span class="btn btn-primary grey-mint fileinput-button">' +
                                    '<span>Foto(s) hinzuf&uuml;gen</span>' +
                                    '<div class="fileuploadBox"></div>' +
                                '</span>';

							//progress bar
							s += '<div class="uploadProgress progress mt15">' +
								'<div class="progress-bar progress-bar-primary"></div>' +
								'<div class="progress-error"></div>' +
							'</div>';

							//file listing
							s += '<div class="table-scrollable" class="display-hide">' +
								'<table data-formFieldId="' + formField.id + '" class="protocolAttachmentTable table table-striped table-hover dataTable no-footer" role="grid">' +
									'<thead>' +
										'<tr role="row">' +
										'	<th class="attachmentPreview">&nbsp;</th>' +
										'	<th>Name</th>' +
										'	<th>Typ</th>' +
										'	<th>Gr&ouml;&szlig;e</th>' +
										'	<th class="text-right">Aktionen</th>' +
										'</tr>' +
										'</thead>' +
										'<tbody>' +
										'</tbody>' +
									'</table>' +
								'</div>' +
							'</div>';

						}*/
						break;
				}

				//Help Block
				if (formField.hint)
					s += '<div class="help-block">' + formField.hint + '</div>';
				
				s += '</div>';

				return s;
			},

			//------------------------------------------------------------------------------------------

			getElementIndex: function(fId){
				for (var i=0; i<this.formFields.length; i++)
					if (this.formFields[i].id === fId)
						return i;
				return -1;
			},

			//---------------------------------------------------

			isValidValue: function(formField, inputValue){

				switch (formField.type){
					case LocationField.FIELD_TYPE_RUNNING_TIME:
						if (!inputValue)
							return false;
						if (inputValue === "")
							return false;
						if (inputValue.length < 5)
							return false;
						var curCounter = (inputValue || "::").split(":");
						for (var i=0; i<curCounter.length; i++){
							if (curCounter[i].length < 1)
								return false;
						}
						break;
					default:
						if (!inputValue)
							return false;
						if (inputValue === "")
							return false;
						break;
				}

				return true;
			},

			validate: function(showErrors){

				var errorIndices = [];
				if (showErrors){
					$(".protocolForm .item.item-header", this.formEl).removeClass("formError");
					$(".protocolForm .errorIcon", this.formEl).html("");
				}

				var ok = true;
				for (var i=0; i<this.formFields.length; i++){

					var formField = this.formFields[i];
					if (formField.isRequired === 1) {
						
						//alles muss bearbeitet sein
						if (!this.isValidValue(formField, this.elementData[i])) {
							ok = false;
							errorIndices.push(i);
						}
					}
					
					//UPK-1206: immer prüfen, unabh. davon, ob Feld required
					//wenn negativ (z.B. M), dann bitte auch Beschreibung
					var incType = this.getIncidentType2(formField.id, this.elementData[i]);
					if (incType === Incident.INCIDENT_DEFECT){
						var msg = $("#m" + i, this.formEl).val();
						if (msg.length === 0){
							ok = false;
							errorIndices.push(i);
						}
					}
					
				}

				if (showErrors){
					if (!ok){

						//alle Elemente markieren
						for (i=0; i<errorIndices.length; i++){
							this.showElementError(errorIndices[i], true);
						}

						//Fehlerintro anzeigen
						this.showValidation(true);
						
						//zum obersten Eintrag springen
						this.formContainer.scrollTo("sectionHeader_" + errorIndices[0]);
					}
					else
						this.showValidation(false);
				}

				return ok;
			},

			//einzelnes Element mit Fehler anzeigen (oder ausblenden)
			showElementError: function(index, hasError){
				
				if (hasError){
					$("#sectionHeader_" + index, this.formEl).addClass("formError");
					if (this.isApp)
						$("#sectionHeader_" + index + " .errorIcon", this.formEl).html('<i class="icon fa fa-exclamation-triangle fgRed icon-accessory" style="padding-right: 5px;"></i>');
					else
						$("#sectionHeader_" + index + " .errorIcon", this.formEl).html('<i class="icon fa fa-exclamation-triangle font-red icon-accessory" style="padding-right: 5px;"></i>');
				}
				else{
					$("#sectionHeader_" + index, this.formEl).removeClass("formError");
					$("#sectionHeader_" + index + " .errorIcon", this.formEl).html('');
				}
			},
			
			//----------------------------------------------------------------

			//Ansicht wechseln: Validation an/aus
			showValidation: function(vis){
				if (vis){
					$("#formHead", this.formEl).hide();
					$(".formValidation", this.formEl).show();
					$("ion-content", this.formEl).addClass("hasValidation");
				}
				else{
					$("#formHead", this.formEl).show();
					$(".formValidation", this.formEl).hide();
					$(".protocolForm .item.item-header", this.formEl).removeClass("formError");
					$(".protocolForm .errorIcon", this.formEl).html("");
					$("ion-content", this.formEl).removeClass("hasValidation");
				}
			},

			//-----------------------------------------------------------------

			//de-serialize & anwenden
			applyProtocolData: function(protocol){

				var that = this;

				var hasPruefungsKonfigurationChanged = false,
					i,
					index,
					j,
					parentId,
					options,
					buttonIndex,
					button,
					formField;

				//Werte anwenden
				for (i=0; i<protocol.protocolData.length; i++){
					var pdi = protocol.protocolData[i];

					formField = this.getFormField(pdi.formFieldId);
					if (!formField) {
						if (pdi.formFieldId === constants.PROTOCOL_FORMFIELD_NOTES) {
							$("#notes", this.formEl).val(pg.handleNullString(pdi.valueString));
						}
						else{
							hasPruefungsKonfigurationChanged = true;
						}
					}
					else {

						//Wert übernehmen
						switch (formField.type) {
							case LocationField.FIELD_TYPE_STRING:
								this.elementData[i] = pdi.valueString;
								$("#input_string_" + i, this.formEl).val(this.elementData[i]);
								break;
							case LocationField.FIELD_TYPE_LONG_TEXT:
								this.elementData[i] = pdi.valueString;
								$("#input_text_" + i, this.formEl).val(this.elementData[i]);
								break;
							case LocationField.FIELD_TYPE_LIST_OR_STRING:
								this.elementData[i] = pdi.valueString;
								break;
							case LocationField.FIELD_TYPE_LIST_BUTTONS:
								this.elementData[i] = pdi.valueString;
								//Mangelbeschreibung
								options = pg.splitNonEmpty(formField.options, "#");
								buttonIndex = -1;
								for (j = 0; j < options.length; j++)
									if (options[j] === this.elementData[i]) {
										buttonIndex = j;
										break;
									}
								parentId = "bb_" + i;
								button = $("#" + parentId + " a[data-index=\"" + buttonIndex + "\"]", this.formEl);
								this.setButtonValue(button, parentId, i, this.elementData[i]);
								break;
							case LocationField.FIELD_TYPE_INT:
								this.elementData[i] = pdi.valueInt;
								$("#input_int_" + i, this.formEl).val(formField.getFormattedValue(this.elementData[i]));
								break;
							case LocationField.FIELD_TYPE_LIST:
								this.elementData[i] = pdi.valueString;
								this.setSelectValue(formField, this.elementData[i], i);
								break;
							case LocationField.FIELD_TYPE_BOOL:
								this.elementData[i] = pdi.valueInt;
								$("#input_bool_" + i, this.formEl).prop("checked", (this.elementData[i] === 1));
								break;
							case LocationField.FIELD_TYPE_FLOAT:
								this.elementData[i] = pdi.valueFloat;
								$("#input_float_" + i, this.formEl).val(formField.getFormattedValue(this.elementData[i]));
								break;
							case LocationField.FIELD_TYPE_DATE:
								this.elementData[i] = pdi.valueDate;
								if (this.elementData[i]) {
									var d = this.elementData[i].getFullYear() + "-" + pg.forceLength((this.elementData[i].getMonth()+1), 2) + "-" + pg.forceLength(this.elementData[i].getDate(), 2);
									$("#input_date_" + i, this.formEl).val(d);
								}
								break;
							case LocationField.FIELD_TYPE_TIME:
								this.elementData[i] = pdi.valueString;
								$("#input_time_" + i, this.formEl).val(this.elementData[i]);
								break;
							case constants.PROTOCOL_FORMFIELD_NOTES:
								$("#notes", this.formEl).val(pg.handleNullString(pdi.valueString));
								break;
							case LocationField.FIELD_TYPE_RUNNING_TIME:
								this.elementData[i] = pdi.valueString;
								if (this.elementData[i]){
									var curCounter = (this.elementData[i] || "::").split(":");
									if (curCounter[0])
										$("#input_counter_days_" + i, this.formEl).val(curCounter[0]);
									if (curCounter[1])
										$("#input_counter_hours_" + i, this.formEl).val(curCounter[1]);
									if (curCounter[2])
										$("#input_counter_minutes_" + i, this.formEl).val(curCounter[2]);
								}
								break;
						}
					}
				}

				//Vorkommnisse
				for (i=0; i<protocol.incidents.length; i++){
					var inc = protocol.incidents[i];

					index = this.getElementIndex(inc.fieldId);
					formField = this.formFields[index];

					//Mangelbeschreibung
					$("#m" + index, this.formEl).val(inc.description);

					//Signale
					this.signals[index].defect = inc.hasSignal(Signal.SIGNAL_DEFECT);
					this.signals[index].notify = inc.hasSignal(Signal.SIGNAL_NOTIFY);
					if (!inc.hasSignal(Signal.SIGNAL_DEFECT))
						$(".signalBox .signalFixed[data-index=\"" + index + "\"]", this.formEl).addClass("active");
					if (inc.hasSignal(Signal.SIGNAL_NOTIFY))
						$(".signalBox .signalNotify[data-index=\"" + index + "\"]", this.formEl).addClass("active");

				}

				this.validate(false);

				if (hasPruefungsKonfigurationChanged) {
					this.formContainer.getOverlayService().showMessageBox("Hinweis: Die Prüfungskonfiguration des Objektes wurde seit dem Speichern dieses Protokolls verändert. Überflüssige Felder wurden entfernt.");
				}
			},

			//Daten zusammentragen
			extractProtocolData: function(){

				var a = [],
					pdi,
					guid;

				for (var i=0; i<this.formFields.length; i++){

					var ff = this.formFields[i];

					pdi = ProtocolDataItem.createProtocolDataItem();
					//noinspection JSUnresolvedVariable
					guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
					pdi.id = guid * -1;
					pdi.createdOn = new Date();
					pdi.createdBy = -1;
					pdi.changedOn = new Date();
					pdi.changedBy = -1;

					pdi.formFieldId = ff.id;
					switch (ff.type){
						case LocationField.FIELD_TYPE_DIVIDER:
							break;
						case LocationField.FIELD_TYPE_STRING:
						case LocationField.FIELD_TYPE_LIST:
						case LocationField.FIELD_TYPE_LIST_OR_STRING:
						case LocationField.FIELD_TYPE_LONG_TEXT:
						case LocationField.FIELD_TYPE_LIST_BUTTONS:
						case LocationField.FIELD_TYPE_TIME:
						case LocationField.FIELD_TYPE_RUNNING_TIME:
							pdi.valueString = this.elementData[i];
							break;
						case LocationField.FIELD_TYPE_INT:
						case LocationField.FIELD_TYPE_BOOL:
							pdi.valueInt = null;
							if (this.elementData[i] && !isNaN(this.elementData[i]))
								pdi.valueInt = parseInt(this.elementData[i]);
							break;
						case LocationField.FIELD_TYPE_FLOAT:
							pdi.valueFloat = null;
							if (this.elementData[i] && !isNaN(this.elementData[i]))
								pdi.valueFloat = parseFloat(this.elementData[i]);
							break;
						case LocationField.FIELD_TYPE_DATE:
							pdi.valueDate = this.elementData[i] ? pg.parseDate(this.elementData[i]) : null;
							break;
					}

					a.push(pdi);
				}

				//Extra: Notes
				pdi = ProtocolDataItem.createProtocolDataItem();
				//noinspection JSUnresolvedVariable
				guid = Math.floor(Math.random() * constants.MAX_SAFE_INTEGER_MYSQL);
				pdi.id = guid * -1;
				pdi.createdOn = new Date();
				pdi.createdBy = -1;
				pdi.changedOn = new Date();
				pdi.changedBy = -1;
				pdi.formFieldId = constants.PROTOCOL_FORMFIELD_NOTES;
				pdi.valueString = pg.validateInput($("#notes").val(), 4096);
				a.push(pdi);

				return a;
			},

			//--------------------------------------------------------------

			getProtocolStatus: function(){

				//was ist wie ausgefüllt?
				var completeCount = 0;
				for (var i=0; i<this.formFields.length; i++){

					var formField = this.formFields[i];
					if (formField.isRequired === 1){
						if (this.isValidValue(formField, this.elementData[i]))
							completeCount++;
					}
					else
						completeCount++;
				}

				//alles problemlos
				if (completeCount === this.formFields.length)
					return constants.STATUS_COMPLETED;

				//noch am ausfüllen?
				if (completeCount < this.formFields.length)
					return constants.STATUS_PROGRESS;

				//zumindest eine Notiz eingegeben?
				if ($("#notes", this.formEl).val())
					return constants.STATUS_PROGRESS;

				//nein, völlig unberührt
				return constants.STATUS_NEW;
			},

			//---------------------------------------------

			getIncidents: function(){

				//nur aktive Signale übermitteln
				var activeIncidents = [];

				for (var i=0; i<this.formFields.length; i++){

					var formField = this.formFields[i];

					var incType = this.getIncidentType2(formField.id, this.elementData[i]);
					if (incType !== Incident.INCIDENT_NONE){
						//var inc = this.getIncident(i, incType, this.formFields[i].name, $("#m" + i, this.formEl).val(), this.elementData[i]);
						var inc = this.getIncident(i, incType, $("#m" + i, this.formEl).val(), this.elementData[i]);
						var signal = this.signals[i];
						if (signal && signal.defect)
							inc.addSignal(this.getSignal(i, Signal.SIGNAL_DEFECT));
						if (signal && signal.notify)
							inc.addSignal(this.getSignal(i, Signal.SIGNAL_NOTIFY));
						activeIncidents.push(inc);
					}
				}

				return activeIncidents;
			},

			//in DB schreiben, VOs aktualisieren
			callSubmit: function(sendToServer, callback){

				var that = this;

				this.formContainer.submitProtocol(
					sendToServer,
					this.getProtocolStatus(),
					this.extractProtocolData(),
					this.attachments,
					this.getIncidents(),
					function(){
						viewManager.updateButtons();
						if (callback)
							callback();
					});
			},

			//----------------------------------------

			//anwenden
			setButtonValue: function(button, parentId, index, val) {

				//deactivate
				var parent = $("#" + parentId, this.formEl);
				$(".button", parent).removeClass("button-assertive button-balanced button-energized button-dark");

				//formCode aus DB heranziehen
				if (index >= this.formFields.length)
					return;
				var ffId = this.formFields[index].id;
				if (val) {
					var colorClass = this.getColorData2(ffId, val);

					//übersetzen (Backend/App) und aktivieren
					switch (colorClass) {
						case "bgGreen":
							button.addClass("button-balanced");
							break;
						case "bgOrange":
							button.addClass("button-energized");
							break;
						case "bgRed":
							button.addClass("button-assertive");
							break;
						case "bgLightestGray":
						case "bgGray":
							button.addClass("button-dark");
							break;
					}
				}

				//textbox
				var granny = parent.parent();
				granny.find(".button-bar.buttonBarItem").removeClass("hasSignalBoxRed").removeClass("hasSignalBoxYellow");
				var incType = this.getIncidentType2(ffId, val);
				if (incType !== Incident.INCIDENT_NONE){

					if (incType === Incident.INCIDENT_DEFECT){
						$("#m" + index, this.formEl).attr("placeholder", "Geben Sie eine Beschreibung ein... *");
						granny.find(".button-bar.buttonBarItem").addClass("hasSignalBoxRed");
					}
					else{
						$("#m" + index).attr("placeholder", "Geben Sie eine Beschreibung ein...");
						granny.find(".button-bar.buttonBarItem").addClass("hasSignalBoxYellow");
					}
					granny.find(".descBox").fadeIn();
					granny.find(".signalBox").fadeIn();
				}
				else{
					granny.find(".descBox").fadeOut();
					granny.find(".signalBox").fadeOut();
				}

				this.validate(false);
			},

			setSelectValue: function(formField, val, index){

				var that = this;

				//Select setzen
				var selectEl = $("select[data-formFieldId='" + formField.id + "']", this.formEl);
				selectEl.val(val);
				
				//Vorkommnis
				var incType = that.getIncidentType2(formField.id, val);
				var viewEl = selectEl.parent();
				viewEl.removeClass("hasSignalBoxRed").removeClass("hasSignalBoxYellow");
				if (incType !== Incident.INCIDENT_NONE){

					if (incType === Incident.INCIDENT_DEFECT){
						$("#m" + index, that.formEl).attr("placeholder", "Geben Sie eine Beschreibung ein... *");
						viewEl.addClass("hasSignalBoxRed");
					}
					else{
						$("#m" + index).attr("placeholder", "Geben Sie eine Beschreibung ein...");
						viewEl.addClass("hasSignalBoxYellow");
					}
					viewEl.find(".descBox").fadeIn();
					viewEl.find(".signalBox").fadeIn();
				}
				else{
					viewEl.find(".descBox").fadeOut();
					viewEl.find(".signalBox").fadeOut();
				}

			},

			//----------------------------------------------------------------------

			//Fix #613: Der "Go"-Button in Eingabefeldern löste einen Klick auf dem RemoveImage-Button aus (ja, wirklich!)
			fixProtocolForms: function(){
				$('input[type=text]', this.formEl).keypress(function(e) {
					var code = (e.keyCode ? e.keyCode : e.which);
					if ((code === 13) || (code === 10)) {
						$(this).blur();
						return false;
					}
				});
			}


		},
		
		//----------------------------------------------------------
		
		//Werte deserialisieren
		getProtocolDataValues: function (s) {
			return s.split(":");
		},
		
		//----------------------------------------------------------
		
		//u.a. Template-Code einbetten
		updateProtocolForms: function () {

			var p;
			
			for(var i = 0; i < model.protocolForms.length; i++) {
				model.protocolForms[i].isCurrentVersion = true;
				model.protocolForms[i].fields = [];
			}
			
			for(i = 0; i < model.protocolForms.length; i++) {
				p = model.protocolForms[i];
				
				//Versionierung
				if (p.version > 1) {
					//direkten Vorgänger suchen
					for (var k=p.version-1; k>0; k--) {
						var pPrev = ProtocolForm.getProtocolForm(p.formId, k);
						if (pPrev) {
							p.prevVersion = pPrev;
							pPrev.nextVersion = p;
							pPrev.isCurrentVersion = false;
							break;
						}
					}
				}
				
				//Fields zuordnen
				for(var j = 0; j < model.protocolFormFields.length; j++) {
					var ff = model.protocolFormFields[j];
					if (ff.formId === p.formId)
						p.addField(ff);
				}
				
			}
		},
		
		//----------------------------------------------------------
		
		//ein bestimmtes Formular holen
		getProtocolForm: function (formId, version) {
			for(var i = 0; i < model.protocolForms.length; i++) {
				var pf = model.protocolForms[i];
				if (pf.formId === formId) {
					
					if (version === undefined) {
						if (!pf.isCurrentVersion)
							continue;
					}
					else if (pf.version !== version)
						continue;
					
					return pf;
				}
			}
			return null;
		},
		
		//----------------------------------------------------------

		// "&gt;" -> ">"
		decodeHtml: function(s){
			if (s) {
				s = s.replace('&lt;', '<').replace('&gt;', '>');
			}
			return s;
		},
		// ">" -> "&gt;"
		encodeHtml: function(s){
			if (s) {
				s = s.replace('<', '&lt;').replace('>', '&gt;');
			}
			return s;
		},

		//----------------------------------------------------------

		createProtocolForm: function () {
			return Object.create(ProtocolForm.protocolFormPrototype);
		}
	};
	
	window.ProtocolForm = ProtocolForm;
}());
